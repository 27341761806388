import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

import "./i18n";
import "./amplify";
import AuthService from "./services/authService";
import HomePage, { homePagePath } from "./pages/HomePage";
import CustomersPage, { customersPagePath } from "./pages/CustomersPage";
import ProjectsPage, { projectsPagePath } from "./pages/ProjectsPage";
import ProjectCreationPage, { projectCreationPagePath } from "./pages/ProjectCreationPage";
import ProjectPage, { projectPagePath } from "./pages/ProjectPage";
import AnalyzerPage, { analyzerPagePath } from "./pages/AnalyzerPage";
import ProjectEditPage, { projectEditPagePath } from "./pages/ProjectEditPage";

import "./App.scss";
import { projectCopyPagePath } from "./pages/ProjectsPage/ProjectsTable";
import AdminPage, { adminPagePath } from "./pages/AdminPage";

const router = createBrowserRouter([
  {
    path: homePagePath,
    element: <HomePage />,
  },
  {
    path: customersPagePath,
    element: <CustomersPage />,
  },
  {
    path: projectsPagePath,
    element: <ProjectsPage />,
  },
  {
    path: projectCreationPagePath,
    element: <ProjectCreationPage />,
  },
  {
    path: projectEditPagePath,
    element: <ProjectCreationPage />,
  },
  {
    path: projectEditPagePath,
    element: <ProjectEditPage />, // Page not used anymore
  },
  {
    path: projectCopyPagePath,
    element: <ProjectCreationPage />,
  },
  {
    path: projectPagePath,
    element: <ProjectPage />,
  },
  {
    path: analyzerPagePath,
    element: <AnalyzerPage />,
  },
  {
    path: adminPagePath,
    element: <AdminPage />,
  },
  {
    path: "*",
    element: <Navigate replace to={homePagePath} />,
  },
]);

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    AuthService.getCurrentUser()
      .then(() => {
        setIsLoggedIn(true);
      })
      .catch(() => {
        setIsLoggedIn(false);
        AuthService.signIn();
      });
  }, []);

  return isLoggedIn ? <RouterProvider router={router} /> : null;
};

export default App;
