import React from "react";

import Page from "../../hocs/Page";
import Header, { MenuOption } from "../../components/Header";
import Analyzer from "./Analyzer";

import "./AnalyzerPage.scss";

export const analyzerPagePath = "/current-situation-analyzer";

const AnalyzerPage = () => {
  return (
    <Page className="analyzer-page">
      <Header selectedMenuOption={MenuOption.Analyzer} />
      <div className="content">
        <div className="analyzer-container">
          <Analyzer />
        </div>
      </div>
    </Page>
  );
};

export default AnalyzerPage;
