import React, { useEffect, useState } from "react";
import { Form, InputNumber, Select } from "antd";
import { useTranslation } from "react-i18next";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Dosing } from "../../../services/projectService";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

interface Props {
  initial: {
    numberOfUsersOnWater?: number;
    numberOfUsersOnChemicals?: number;
    expansion?: number;
    dosing?: Dosing;
    numberOfChemicals?: number;
    ccu?: boolean;
    waterPressure?: number;
  };
  onNumberOfUsersOnWaterChange: (value?: number) => void;
  onNumberOfUsersOnChemicalsChange: (value?: number) => void;
  onExpansionChange: (value: number) => void;
  onDosingChange: (value: Dosing) => void;
  onNumberOfChemicals: (value?: number) => void;
  onCcuChange: (value: boolean) => void;
}

const SystemUsersStep: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();

  const [expansionChecked, setExpansionChecked] = useState(!!props.initial.expansion);
  const [ccuCompatible, setCcuCompatible] = useState(false);
  const [ccuChecked, setCcuChecked] = useState(!!props.initial.ccu);
  const [selectedWaterPressure, setSelectedWaterPressure] = useState<number | undefined>(props.initial.waterPressure);

  useEffect(() => {
    if (
      props.initial.numberOfUsersOnWater !== undefined &&
      props.initial.numberOfUsersOnWater <= 5 &&
      props.initial.numberOfUsersOnChemicals !== undefined &&
      props.initial.numberOfUsersOnChemicals <= 6 &&
      props.initial.numberOfChemicals !== undefined &&
      props.initial.numberOfChemicals < 3 &&
      props.initial.dosing !== undefined &&
      props.initial.dosing === "central"
    ) {
      setCcuCompatible(true);
    } else {
      setCcuCompatible(false);
    }
  }, [
    props.initial.numberOfUsersOnWater,
    props.initial.numberOfUsersOnChemicals,
    props.initial.dosing,
    props.initial.numberOfChemicals,
  ]);

  useEffect(() => {
    setSelectedWaterPressure(props.initial.waterPressure);
  }, [props.initial.waterPressure]);

  return (
    <Form
      initialValues={props.initial}
      labelWrap
      labelCol={{ offset: 4, span: 6 }}
      wrapperCol={{ span: 8 }}
      requiredMark="optional"
      colon={false}
    >
      <Form.Item
        name="numberOfUsersOnWater"
        label={t("projectCreationPage.step6.numberOfUsersOnWater")}
        rules={[{ required: true, message: <></> }]}
      >
        <InputNumber
          onChange={(value: number | null) => props.onNumberOfUsersOnWaterChange(value === null ? undefined : value)}
        />
      </Form.Item>
      {props.initial.dosing !== "decentral" && (
        <Form.Item
          name="numberOfUsersOnChemicals"
          label={t("projectCreationPage.step6.numberOfSimultaneousUsersOnChemical")}
          rules={[{ required: true, message: <></> }]}
        >
          <InputNumber
            onChange={(value: number | null) =>
              props.onNumberOfUsersOnChemicalsChange(value === null ? undefined : value)
            }
          />
        </Form.Item>
      )}

      {ccuCompatible && (
        <Form.Item
          label="Select CCU"
          tooltip="Checking this box will replace separate LWP and VCC II units with a corresponding Combi Unit - CCU. No expansion possible"
          requiredMark={false}
        >
          <Checkbox
            checked={ccuChecked}
            onChange={(e: CheckboxChangeEvent) => {
              setCcuChecked(e.target.checked);
              props.onCcuChange(e.target.checked);
              if (e.target.checked) {
                props.onExpansionChange(0);
                setExpansionChecked(false);
              }
            }}
          />
        </Form.Item>
      )}
      {!ccuChecked && (
        <Form.Item
          name="expansion"
          label={t("projectCreationPage.step6.expansion")}
          rules={[{ required: true, message: <></> }]}
        >
          <Checkbox
            checked={expansionChecked}
            onChange={(e: CheckboxChangeEvent) => {
              setExpansionChecked(e.target.checked);
              props.onExpansionChange(e.target.checked ? props.initial.expansion || 0 : 0);
            }}
          />
        </Form.Item>
      )}
      {expansionChecked && (
        <Form.Item
          name="expansion"
          label={t("projectCreationPage.step6.expansionUsers")}
          rules={[{ required: true, message: <></> }]}
          dependencies={["expansion"]}
          // shouldUpdate={(prevValues, curValues) => prevValues.expansion !== curValues.expansion}
        >
          <Select value={props.initial.expansion} onChange={(value) => props.onExpansionChange(value)}>
            {[0, 20, 30].map((exp) =>
              selectedWaterPressure === 20 && exp === 20 ? null : (
                <Select.Option key={exp} value={exp}>
                  {exp}
                </Select.Option>
              )
            )}
          </Select>
        </Form.Item>
      )}
    </Form>
  );
};

export default SystemUsersStep;
