import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "antd";

import CustomerService, { Customer } from "../../services/customerService";
import Page from "../../hocs/Page";
import Header, { MenuOption } from "../../components/Header";
import CustomersTable from "./CustomersTable";

import "./CustomersPage.scss";

export const customersPagePath = "/customers";

const CustomersPage = () => {
  const { t } = useTranslation();

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    CustomerService.getCustomers()
      .then((customers) => {
        setCustomers(customers);
        setFilteredCustomers(customers);
      })
      .catch(() => {}); // TODO: Handle error
  }, []);

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredCustomers(customers);
    } else {
      setFilteredCustomers(customers.filter((customer) =>
        customer.name.toLowerCase().includes(searchQuery.toLowerCase())
      ));
    }
  }, [searchQuery, customers]);

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  return (
    <Page className="customers-page">
      <Header selectedMenuOption={MenuOption.Customers} />
      <div className="actions">
        <Input.Search
          className="search-input"
          placeholder={t("customersPage.searchInputPlaceholder")}
          onSearch={handleSearch}
        />
      </div>
      <CustomersTable className="customers-table" customers={filteredCustomers} />
    </Page>
  );
};

export default CustomersPage;
