import React from "react";
import { useTranslation } from "react-i18next";
import { Descriptions, Select, InputNumber } from "antd";

import { Project } from "../../../services/projectService";

import "./Details.scss";

interface Props {
  project: Project;
  customer: string;
  status: string;
  probability: number;
  onStatusChange: (status: string) => void;
  onProbabilityChange: (probability: number) => void;
}

const Details: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Descriptions className="details" column={6} bordered layout="horizontal">
      <Descriptions.Item span={6} label="ID">
        {props.project.id}
      </Descriptions.Item>
      <Descriptions.Item span={6} label="Name">
        {props.project.name}
      </Descriptions.Item>
      <Descriptions.Item span={6} label="Customer">
        {props.customer}
      </Descriptions.Item>
      <Descriptions.Item span={6} label="Created at">
        {props.project.createdAt}
      </Descriptions.Item>
      <Descriptions.Item span={6} label="Project status">
        <Select value={props.status} onChange={(value) => props.onStatusChange(value)} style={{ width: "200px" }}>
          <Select.Option value="active">Active</Select.Option>
          <Select.Option value="ordered">Ordered</Select.Option>
          <Select.Option value="notOrdered">Not ordered</Select.Option>
        </Select>
      </Descriptions.Item>
      <Descriptions.Item span={6} label="Probability">
        <InputNumber
          value={props.probability}
          onChange={(value) => props.onProbabilityChange(value || 0)}
          addonAfter="%"
          style={{ width: "200px" }}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Answers">
        <Descriptions bordered layout="horizontal">
          <Descriptions.Item label="Production">
            {t(`project.productionType.${props.project.productionType}`)}
          </Descriptions.Item>
          <Descriptions.Item label="Water pressure">{props.project.waterPressure}</Descriptions.Item>
          <Descriptions.Item label="Dosing">{t(`project.dosing.${props.project.dosing}`)}</Descriptions.Item>
          <Descriptions.Item label="Number of chemicals">{props.project.numberOfChemicals}</Descriptions.Item>
          <Descriptions.Item label="Number of satellite stations">
            {props.project.numberOfSatelliteStations}
          </Descriptions.Item>
          <Descriptions.Item label="Number of users on water">{props.project.numberOfUsersOnWater}</Descriptions.Item>
          <Descriptions.Item label="Number of users on chemicals">
            {props.project.numberOfUsersOnChemicals}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Expansion">{props.project.expansion}</Descriptions.Item> */}
          <Descriptions.Item label="CCU">{props.project.ccu ? "True" : "False"}</Descriptions.Item>
          <Descriptions.Item label="LTS">{props.project.lts ? "True" : "False"}</Descriptions.Item>
          <Descriptions.Item label="Pipes">{props.project.pipes ? "True" : "False"}</Descriptions.Item>
          <Descriptions.Item label="Installation">{props.project.installation ? "True" : "False"}</Descriptions.Item>
          <Descriptions.Item label="Service agreement 36 months">
            {props.project.serviceAgreement36Months ? "True" : "False"}
          </Descriptions.Item>
        </Descriptions>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default Details;
