import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import ProjectService, { Project } from "../../services/projectService";
import { projectCreationPagePath } from "../ProjectCreationPage";
import { projectPagePath } from "../ProjectPage";
import Page from "../../hocs/Page";
import Header, { MenuOption } from "../../components/Header";
import ProjectsTable from "./ProjectsTable";

import "./ProjectsPage.scss";
import CustomerService, { Customer } from "../../services/customerService";

export const newProjectPagePath = "/create-project";
export const projectsPagePath = "/projects";

const ProjectsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [projects, setProjects] = useState<Project[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
  const [isSearchActive, setIsSearchActive] = useState(true);

  useEffect(() => {
    fetchProjects();
    fetchCustomers();
  }, []);

  useEffect(() => {
    setFilteredProjects(projects);
  }, [projects]);

  useEffect(() => {
    const contentContainer = document.querySelector(".table");
    if (contentContainer) {
      if (isSearchActive) {
        contentContainer.classList.add("blur");
      } else {
        contentContainer.classList.remove("blur");
      }

      // Cleanup to remove the class if the component unmounts
      return () => {
        contentContainer.classList.remove("blur");
      };
    }
  }, [isSearchActive]);

  const fetchProjects = () => {
    ProjectService.getProjects()
      .then((projects) => {
        setProjects(projects);
      })
      .catch(() => {}); // TODO: Handle error
  };

  const fetchCustomers = () => {
    CustomerService.getCustomers()
      .then((customers) => {
        setCustomers(customers);
      })
      .catch(() => {
        setCustomers([]);
      });
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSearchActive(true);
    const searchText = event.target.value.toLowerCase();
    const filtered = projects.filter((project) => {
      const customer = customers.find((c) => c.id === project.customer);
      return customer && customer.name.toLowerCase().includes(searchText);
    });
    setFilteredProjects(filtered);
  };

  const handleCreateProject = () => {
    navigate(projectCreationPagePath);
  };

  const handleViewProject = (projectId: string) => {
    navigate(projectPagePath.replace(":projectId", projectId));
  };

  const handleDeleteProject = async (projectId: string) => {
    try {
      await ProjectService.deleteProject(projectId);
      fetchProjects(); // Fetch the projects again after successful deletion
    } catch (error) {
      // TODO: handle the error
    }
  };

  return (
    <Page className="projects-page">
      <Header selectedMenuOption={MenuOption.Projects} />
      <div className="actions">
        <Input.Search
          autoFocus
          className="search-input"
          placeholder={t("projectsPage.searchInputPlaceholder")}
          onChange={handleSearch}
          enterButton
          onSearch={() => setIsSearchActive(false)}
          onPressEnter={() => setIsSearchActive(false)}
        />
        <Button onClick={handleCreateProject}>
          <PlusOutlined />
          {t("projectsPage.addProjectButton")}
        </Button>
      </div>
      <ProjectsTable
        className="table"
        projects={filteredProjects}
        customers={customers}
        onViewProjectButtonClick={handleViewProject}
        onDeleteProject={handleDeleteProject}
      />
    </Page>
  );
};

export default ProjectsPage;
