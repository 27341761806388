import React from "react";
import { Form, InputNumber } from "antd";
import { locale, Currency } from "../../../ProjectCreationPage/locale";

interface Props {
  initial: {
    numberOfCleaningSessions?: number;
    numberOfCleaners?: number;
    cleaningSessionDuration?: number;
    flushingDuration?: number;
    cleanerCost?: number;
  };
  currency: Currency;
  onNumberOfCleaningSessionsChange: (value?: number) => void;
  onNumberOfCleanersChange: (value?: number) => void;
  onCleaningSessionDurationChange: (value?: number) => void;
  onFlushingDurationChange: (value?: number) => void;
  onCleanerCostChange: (value?: number) => void;
}

const MIN_NUMBER_OF_CLEANING_SESSIONS = 0;
const MIN_NUMBER_OF_CLEANERS = 0;
const MIN_CLEANING_SESSION_DURATION = 0;
const MIN_FLUSHING_DURATION = 0;
const MIN_CLEANER_COST = 0;
const BOX_WIDTH = '45px';

const CurrentLabourStep: React.FunctionComponent<Props> = (props) => {
  return (
    <Form
      initialValues={props.initial}
      labelWrap
      labelCol={{ offset: 4, span: 6 }}
      wrapperCol={{ span: 8 }}
      requiredMark="optional"
      colon={false}
    >
      <Form.Item
        name="numberOfCleaningSessions"
        label="Number of cleaning sessions"
        rules={[{ required: true, message: <></> }]}
        tooltip={<div>Number of cleaning sessions per year (5 days a week = 260 sessions)</div>}
      >
        <InputNumber
          min={MIN_NUMBER_OF_CLEANING_SESSIONS}
          onChange={(value) => props.onNumberOfCleaningSessionsChange(value === null ? undefined : value)}
          decimalSeparator=","
        />
      </Form.Item>
      <Form.Item
        name="numberOfCleaners"
        label="Number of cleaners"
        rules={[{ required: true, message: <></> }]}
        tooltip={<div>Number of cleaners per cleaning shift</div>}
      >
        <InputNumber
          min={MIN_NUMBER_OF_CLEANERS}
          onChange={(value) => props.onNumberOfCleanersChange(value === null ? undefined : value)}
          decimalSeparator=","
        />
      </Form.Item>
      <Form.Item
        name="cleaningSessionDuration"
        label="Cleaning session duration"
        rules={[{ required: true, message: <></> }]}
        tooltip={<div>Cleaning session duration per person</div>}
      >
        <InputNumber
          min={MIN_CLEANING_SESSION_DURATION}
          addonAfter={<span style={{ width: BOX_WIDTH, display: 'inline-block' }}>hours</span>}
          onChange={(value) => props.onCleaningSessionDurationChange(value === null ? undefined : value)}
          decimalSeparator=","
        />
      </Form.Item>
      <Form.Item
        name="flushingDuration"
        label="Rinse duration"
        rules={[{ required: true, message: <></> }]}
        tooltip={<div>Total rinsing duration per person (including pre-rinse, rinse and final rinse)</div>}
      >
        <InputNumber
          min={MIN_FLUSHING_DURATION}
          addonAfter={<span style={{ width: BOX_WIDTH, display: 'inline-block' }}>hours</span>}
          onChange={(value) => props.onFlushingDurationChange(value === null ? undefined : value)}
          decimalSeparator=","
        />
      </Form.Item>
      <Form.Item
        name="cleanerCost"
        label="Cleaner cost"
        rules={[{ required: true, message: <></> }]}
        tooltip={<div>Hourly cost per cleaner, own staff or hired staff</div>}
      >
        <InputNumber
          min={MIN_CLEANER_COST}
          addonAfter={<span style={{ width: BOX_WIDTH, display: 'inline-block' }}>{locale[props.currency].cleanerCost}</span>}
          onChange={(value) => props.onCleanerCostChange(value === null ? undefined : value)}
          decimalSeparator=","
        />
      </Form.Item>
    </Form>
  );
};

export default CurrentLabourStep;
