import React, { useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Table, Modal } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/es/table/interface";

import { Project } from "../../../services/projectService";

import "./ProjectsTable.scss";
import { Customer } from "../../../services/customerService";

export const projectCopyPagePath = "/projects/:projectId/copy";

interface Props {
  projects: Project[];
  customers: Customer[];
  onViewProjectButtonClick: (projectId: string) => void;
  onDeleteProject: (projectId: string) => Promise<void>;
  className?: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue | null>;
}

const sortByName = (a: Project, b: Project) => a.name.localeCompare(b.name);

const sortByCustomer = (customers: Customer[]) => (a: Project, b: Project) => {
  const aCustomer = customers.find((customer) => customer.id === a.customer);
  const bCustomer = customers.find((customer) => customer.id === b.customer);
  return (aCustomer?.name || "").localeCompare(bCustomer?.name || "");
};

const sortByCreatedAt = (a: Project, b: Project) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();

const sortByStatus = (a: Project, b: Project) => a.status.localeCompare(b.status);

const ProjectsTable: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const [deletingProjects, setDeletingProjects] = useState<Record<string, boolean>>({});

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Project> | SorterResult<Project>[]
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const handleDeleteProject = async (projectId: string, projectName: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete the project "${projectName}"?`,
      content: "You will not be able to undo this action.",
      onOk: async () => {
        setDeletingProjects((prevState) => ({
          ...prevState,
          [projectId]: true,
        }));
        try {
          await props.onDeleteProject(projectId);
        } catch (error) {
          // TODO: handle the error
        } finally {
          setDeletingProjects((prevState) => ({
            ...prevState,
            [projectId]: false,
          }));
        }
      },
      onCancel() {
        // You can do something when user presses cancel
      },
    });
  };

  const columns: ColumnsType<Project> = [
    {
      title: t("projectsPage.projectsTable.name"),
      dataIndex: "name",
      sorter: sortByName,
      align: "center",
      showSorterTooltip: false,
    },
    {
      title: t("projectsPage.projectsTable.customer"),
      dataIndex: "customer",
      sorter: sortByCustomer(props.customers),
      align: "center",
      showSorterTooltip: false,
      render: (value) => props.customers.find((customer) => customer.id === value)?.name || value,
    },
    {
      title: t("projectsPage.projectsTable.createdAt"),
      dataIndex: "createdAt",
      sorter: sortByCreatedAt,
      align: "center",
      showSorterTooltip: false,
      defaultSortOrder: "descend",
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: sortByStatus,
      align: "center",
      showSorterTooltip: false,
      render: (value) => {
        if (value === "notOrdered") {
          return "Not ordered";
        }
        return value;
      },
    },
    {
      align: "center",
      render: (_, project) => (
        <div className="actions-cell">
          <Button onClick={() => props.onViewProjectButtonClick(project.id)}>View</Button>
          <Button
            onClick={() => navigate(generatePath(projectCopyPagePath, { projectId: project.id ?? "" }))}
            className="copy-button"
          >
            Copy
          </Button>
          <Button
            danger
            loading={deletingProjects[project.id]}
            onClick={() => handleDeleteProject(project.id, project.name)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Table
      className={props.className ? `projects-table ${props.className}` : "projects-table"}
      bordered
      columns={columns}
      dataSource={props.projects.map((project) => ({ ...project, key: project.id }))}
      pagination={tableParams.pagination}
      onChange={handleTableChange}
    />
  );
};

export default ProjectsTable;
