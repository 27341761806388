import React from "react";
import { Card, Checkbox, Input } from "antd";

import "./SystemServiceStep.scss";

interface Props {
  lts: boolean;
  pipes: boolean;
  installation: boolean;
  serviceAgreement36Months: boolean;
  plans: string;
  onLtsChange: (value: boolean) => void;
  onPipesChange: (value: boolean) => void;
  onInstallationChange: (value: boolean) => void;
  onServiceAgreement36MonthsChange: (value: boolean) => void;
  onPlansChange: (value: string) => void;
}

const SystemServiceStep: React.FunctionComponent<Props> = (props) => {
  return (
    <div className="system-service-step">
      <Card
        className="agreement-card"
        title={
          <div>
            LTS
            <Checkbox
              className="card-title-checkbox"
              checked={props.lts}
              onChange={(e) => {
                props.onLtsChange(e.target.checked);
              }}
            />
          </div>
        }
      >
        Hardware is mounted into the central units and delivered key ready. Permanent internet access is needed
        (customer supplies) and you can then log into the Lagafors platform for logging, documentation and traceability
        of the cleaning process.
      </Card>
      <Card
        className="agreement-card"
        title={
          <div>
            Pipes
            <Checkbox
              className="card-title-checkbox"
              checked={props.pipes}
              onChange={(e) => {
                props.onPipesChange(e.target.checked);
              }}
            />
          </div>
        }
      >
        All material for complete pipelines between the central units and the satellite stations. Only SS pipes in the
        production area and chemical resistant plastic pipes for the chemicals on installation floor.
      </Card>
      <Card
        className="agreement-card"
        title={
          <div>
            Installation
            <Checkbox
              className="card-title-checkbox"
              checked={props.installation}
              onChange={(e) => {
                props.onInstallationChange(e.target.checked);
              }}
            />
          </div>
        }
      >
        Equipment and pipe lines installed on site. The complete system is handed over key ready to the customer.
        Technical and operator training is included.
      </Card>
      <Card
        className="agreement-card"
        title={
          <div>
            Service Agreement 36 months
            <Checkbox
              className="card-title-checkbox"
              checked={props.serviceAgreement36Months}
              onChange={(e) => {
                props.onServiceAgreement36MonthsChange(e.target.checked);
              }}
            />
          </div>
        }
      >
        Technician from Lagafors visits the plant 1-2 times annually, to maintain function and access to the cleaning
        system. Travel-, and labor cost is included as well as spare parts needed. The scope includes central units and
        satellites stations (hoses, guns and nozzles etc, is not included).
      </Card>
      <Card className="agreement-card" title="Future plans and other comments">
        <Input.TextArea rows={4} value={props.plans} onChange={(e) => props.onPlansChange(e.target.value)} />
      </Card>
    </div>
  );
};

export default SystemServiceStep;
