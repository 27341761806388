import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, InputNumber, Select } from "antd";

import { Currency } from "../../../ProjectCreationPage/locale";
import { ProductionType } from "../../../../services/projectService";
import { Customer } from "../../../../services/customerService";

const BOX_WIDTH = "65px";

interface Props {
  initial: {
    currency?: Currency;
    customer?: string;
    productionType?: ProductionType;
    productionAmount?: number;
    productionUnit?: string;
  };
  customers: Customer[];
  onCurrencyChange: (currency: Currency) => void;
  onProductionTypeChange: (productionType: ProductionType) => void;
  onCustomerChange: (value: string) => void;
  onProductionAmountChange: (value?: number) => void;
  onProductionUnitChange: (value: string) => void;
}

const ContextStep: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const units = [
    {
      name: "Euro / Metric",
      value: Currency.Euro,
    },
    {
      name: "Swedish Krona / Metric",
      value: Currency.Krona,
    },
    {
      name: "US Dollar / Imperial",
      value: Currency.Dollar,
    },
  ];

  const productionTypes = [
    {
      name: t("project.productionType.meat"),
      value: ProductionType.Meat,
    },
    {
      name: "Vegetarian",
      value: ProductionType.Vegetarian,
    },
    {
      name: t("project.productionType.bakery"),
      value: ProductionType.Bakery,
    },
    {
      name: t("project.productionType.beverage"),
      value: ProductionType.Beverage,
    },
    {
      name: t("project.productionType.preparedFood"),
      value: ProductionType.PreparedFood,
    },
    {
      name: t("project.productionType.dairy"),
      value: ProductionType.Dairy,
    },
    {
      name: t("project.productionType.poultry"),
      value: ProductionType.Poultry,
    },
    {
      name: t("project.productionType.fish"),
      value: ProductionType.Fish,
    },
    {
      name: t("project.productionType.other"),
      value: ProductionType.Other,
    },
  ];

  const handleCustomerChange = (value: string) => {
    const customer = props.customers.find((c) => c.id === value);
    switch (customer?.currency) {
      case "EUR":
        handleCurrencyChange(Currency.Euro);
        break;
      case "SEK":
        handleCurrencyChange(Currency.Krona);
        break;
      case "USD":
        handleCurrencyChange(Currency.Dollar);
        break;
      default:
        handleCurrencyChange(Currency.Krona);
        break;
    }
    props.onCustomerChange(value);
  };

  const handleCurrencyChange = (value: Currency) => {
    if (form.getFieldValue("units") === value) return; // Don't update if set
    props.onCurrencyChange(value);
    form.setFieldValue("units", value);
  };

  return (
    <Form
      form={form}
      initialValues={props.initial}
      labelWrap
      labelCol={{ offset: 4, span: 6 }}
      wrapperCol={{ span: 8 }}
      requiredMark="optional"
      colon={false}
    >
      <Form.Item name="customer" label="Customer" rules={[{ required: true, message: <></> }]}>
        <Select
          showSearch
          filterOption
          onChange={handleCustomerChange}
          optionFilterProp="label"
          options={props.customers
            .filter((customer) => customer.status === "OK")
            .map((customer) => ({
              value: customer.id,
              label: customer.name,
            }))}
        />
      </Form.Item>

      <Form.Item name="units" label="Units" rules={[{ required: true, message: <></> }]}>
        <Select onChange={handleCurrencyChange}>
          {units.map((unit) => (
            <Select.Option key={unit.value} value={unit.value}>
              {unit.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="productionType" label="Production type" rules={[{ required: true, message: <></> }]}>
        <Select onChange={(value) => props.onProductionTypeChange(value)}>
          {productionTypes.map((productionType) => (
            <Select.Option key={productionType.value} value={productionType.value}>
              {productionType.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="productionAmount"
        label="Annual production"
        rules={[{ required: true, message: <></> }]}
        tooltip="Annual production in stock keeping unit"
      >
        <div style={{ display: "flex" }}>
          <InputNumber
            onChange={(value) => props.onProductionAmountChange(value === null ? undefined : Number(value))}
          />
          <Input
            style={{ width: BOX_WIDTH, display: "inline-block" }}
            onChange={(e) => props.onProductionUnitChange(e.target.value)}
            placeholder="unit"
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export default ContextStep;
