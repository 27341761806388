import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

class AuthService {
  static async getCurrentUser(): Promise<CognitoUser> {
    return Auth.currentAuthenticatedUser();
  }

  static signIn() {
    Auth.federatedSignIn({ customProvider: "lagafors-ad" });
  }

  static signOut() {
    Auth.signOut();
  }
}

export default AuthService;
