import React from "react";
import { Form, InputNumber } from "antd";

interface Props {
  initial: { numberOfChemicals?: number; dosing?: string };
  onNumberOfChemicalsChange: (value?: number) => void;
}

const MIN_NUMBER_OF_CHEMICALS = 1;
const MAX_NUMBER_OF_CHEMICALS_CENTRAL = 4;
const MAX_NUMBER_OF_CHEMICALS_DECENTRAL = 3;

const SystemChemicalsStep: React.FunctionComponent<Props> = ({ initial, onNumberOfChemicalsChange }) => {
  const { dosing, numberOfChemicals } = initial;
  const maxNumberOfChemicals =
    dosing === "decentral" ? MAX_NUMBER_OF_CHEMICALS_DECENTRAL : MAX_NUMBER_OF_CHEMICALS_CENTRAL;

  // Ensure the initial number of chemicals does not exceed the maximum limit
  const adjustedNumberOfChemicals =
    numberOfChemicals && numberOfChemicals > maxNumberOfChemicals ? maxNumberOfChemicals : numberOfChemicals;

  return (
    <Form
      initialValues={{ ...initial, numberOfChemicals: adjustedNumberOfChemicals }}
      labelWrap
      labelCol={{ offset: 4, span: 6 }}
      wrapperCol={{ span: 8 }}
      requiredMark="optional"
      colon={false}
    >
      <Form.Item
        name="numberOfChemicals"
        label="Number of chemicals"
        rules={[{ required: true, message: <></> }]}
        tooltip={dosing === "decentral" ? <div>1, 2 or 3 different dosing rate</div> : undefined}
      >
        <InputNumber
          min={MIN_NUMBER_OF_CHEMICALS}
          max={maxNumberOfChemicals}
          onChange={(value: number | null) => onNumberOfChemicalsChange(value === null ? undefined : value)}
        />
      </Form.Item>
    </Form>
  );
};

export default SystemChemicalsStep;
