import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/es/table/interface";

import { Customer } from "../../../services/customerService";

import "./CustomersTable.scss";

interface Props {
  customers: Customer[];
  className?: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue | null>;
}

const CustomersTable: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Customer> | SorterResult<Customer>[]
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const columns: ColumnsType<Customer> = [
    {
      title: t("customersPage.customersTable.id"),
      dataIndex: "id",
      sorter: true,
      align: "center",
      showSorterTooltip: false,
    },
    {
      title: t("customersPage.customersTable.name"),
      dataIndex: "name",
      sorter: true,
      align: "center",
      showSorterTooltip: false,
    },
  ];

  const okCustomers = props.customers.filter(customer => customer.status === 'OK');


  return (
    <Table
      className={props.className ? `customers-table ${props.className}` : "customers-table"}
      bordered
      columns={columns}
      dataSource={okCustomers.map((customer) => ({ ...customer, key: customer.id }))}
      pagination={tableParams.pagination}
      onChange={handleTableChange}
    />
  );
};

export default CustomersTable;
