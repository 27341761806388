import React, { useEffect, useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { Button, Card, Image, Modal, InputNumber } from "antd";

import { Project, ProjectProduct } from "../../../services/projectService";

import "./Products.scss";

interface Props {
  project: Project;
  images: { [key: string]: string };
  updateProduct: (id: string, amount: number) => void;
}

const Products: React.FunctionComponent<Props> = (props) => {
  const [groupedByFamily, setGroupedByFamily] = useState<{ [key: string]: string[] }>({});
  const [productsByFamily, setProductsByFamily] = useState<{ [key: string]: ProjectProduct[] }>({});
  const [isHiddenByFamily, setHiddenByFamily] = useState<{ [key: string]: boolean }>({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentFamily, setCurrentFamily] = useState("");

  useEffect(() => {
    const initialValues: { [key: string]: ProjectProduct[] } = {};
    const initialGroups: { [key: string]: string[] } = {};

    props.project.products.forEach((product) => {
      const family = product.family;
      const group = getProductFamilyGroup(product);

      if (!initialGroups[group]) {
        initialGroups[group] = [];
      }

      if (!initialGroups[group].includes(family)) {
        initialGroups[group].push(family);
      }

      if (!initialValues[family]) {
        initialValues[family] = [];
      }
      initialValues[family].push(product);
    });

    setProductsByFamily(initialValues);
    setGroupedByFamily(initialGroups);
    setHiddenByFamily(Object.keys(initialValues).reduce((acc, family) => ({ ...acc, [family]: true }), {}));
  }, [props.project.products]);

  const familyDict: { [key: string]: string } = {
    central_water_unit: "Central water unit",
    central_chemicals_unit: "Central chemicals unit",
    central_combi_unit: "Central combi unit",
    satellite_station: "Satellite station",
    decentral_combined: "Decentral combined",
    hose_kit: "Hose kit",
    hose_reel: "Hose reel",
    hose_accessories: "Hose accessories",
    nozzle_kit: "Nozzle kit",
    misc_accessories: "Misc accessories",
    pallet: "Pallet",
    emergency_shower: "Emergency shower",
  };

  function getProductFamilyGroup(product: ProjectProduct): string {
    switch (product.family) {
      case "central_water_unit":
      case "central_chemicals_unit":
      case "central_combi_unit":
      case "satellite_station":
      case "decentral_combined":
        return props.project.dosing === "central" ? "CCS - Central Cleaning System" : "DCS - Decentral Cleaning System";
      case "hose_kit":
      case "hose_reel":
      case "hose_accessories":
        return "Hose Accessories";
      case "nozzle_kit":
      case "misc_accessories":
        return "Misc Accessories";
      case "pallet":
        return "Collection vessels";
      case "emergency_shower":
        return "Emergency Shower";
      default:
        return "Other";
    }
  }

  const showModal = (family: string) => {
    setCurrentFamily(family);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const toggleVisibility = (family: string) => {
    showModal(family);
  };

  return (
    <div>
      {Object.entries(groupedByFamily).map(([group, families]) => (
        <div key={group} className="product-group">
          <h1>{group}</h1>
          <div className="product-row">
            {families
              .sort((a, b) => {
                const hasProductWithAmountA = productsByFamily[a]?.some((product) => product.amount > 0);
                const hasProductWithAmountB = productsByFamily[b]?.some((product) => product.amount > 0);

                if (hasProductWithAmountA && !hasProductWithAmountB) {
                  return -1;
                }
                if (!hasProductWithAmountA && hasProductWithAmountB) {
                  return 1;
                }
                return 0;
              })
              .map((family) => {
                const hasProductWithAmount = productsByFamily[family]?.some((product) => product.amount > 0);
                const backgroundColor = hasProductWithAmount ? "#f0f6fc" : "#ffffff";

                return (
                  <div key={family} className="family-section" style={{ backgroundColor }}>
                    <div className="family-header">
                      <Button
                        className="toggleButton"
                        icon={<SettingOutlined />}
                        type="primary"
                        ghost
                        onClick={() => toggleVisibility(family)}
                      />
                      <h3>{familyDict[family]}</h3>
                    </div>
                    <div className="products">
                      {productsByFamily[family].map(
                        (product) =>
                          product.amount > 0 && (
                            <Card
                              className="product"
                              key={product.id}
                              title={product.name}
                              style={{
                                display: product.amount === 0 && isHiddenByFamily[family] ? "none" : "block",
                              }}
                              actions={[
                                <div className="amount-action">
                                  <InputNumber
                                    style={{ width: "105px" }}
                                    size="small"
                                    addonAfter="pcs"
                                    min={0}
                                    value={product.amount}
                                    onChange={(value) => props.updateProduct(product.id, value ?? 0)}
                                  />
                                </div>,
                              ]}
                            >
                              <Image
                                className="card-image"
                                preview={false}
                                src={`data:image/jpeg;base64,${props.images[product.id]}`}
                              />
                            </Card>
                          )
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ))}
      <Modal
        title={`Change Products for ${currentFamily}`}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1200}
      >
        <div
          className="modal-products"
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", rowGap: "16px", columnGap: "16px" }}
        >
          {productsByFamily[currentFamily]?.map((product) => (
            <Card
              className="product"
              key={product.id}
              title={product.name}
              type="inner"
              actions={[
                <div className="amount-action">
                  <InputNumber
                    style={{ width: "105px" }}
                    size="small"
                    addonAfter="pcs"
                    min={0}
                    value={product.amount}
                    onChange={(value) => props.updateProduct(product.id, value ?? 0)}
                  />
                </div>,
              ]}
            >
              <Image
                className="card-image"
                preview={false}
                src={`data:image/jpeg;base64,${props.images[product.id]}`}
              />
            </Card>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default Products;
