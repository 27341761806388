import React from "react";
import { Form, Select } from "antd";
import { ProductionType } from "../../../services/projectService";
import { locale, Currency } from "../locale";

interface Props {
  initial: { waterPressure?: number };
  currency: Currency;
  productionType: ProductionType;
  onWaterPressureChange: (value?: number) => void;
}

const SystemWaterStep: React.FunctionComponent<Props> = (props) => {
  const waterPressures = [locale[props.currency].pressure1, locale[props.currency].pressure2];

  return (
    <Form
      initialValues={props.initial}
      labelWrap
      labelCol={{ offset: 4, span: 6 }}
      wrapperCol={{ span: 8 }}
      requiredMark="optional"
      colon={false}
    >
      <Form.Item
        name="waterPressure"
        label={`Water pressure [${locale[props.currency].pressureUnit}]`}
        rules={[{ required: true, message: <></> }]}
      >
        <Select onChange={(value) => props.onWaterPressureChange(value)}>
          {waterPressures.map((pressure) => (
            <Select.Option key={pressure} value={pressure}>
              {pressure}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default SystemWaterStep;
