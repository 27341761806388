import React from "react";
import { Form, InputNumber, Select } from "antd";
import { ProductionType } from "../../../../services/projectService";
import { locale, Currency } from "../../../ProjectCreationPage/locale";

const MIN_TIME_SAVINGS_WITH_CCS = 0;

interface Props {
  initial: { suggestedPressure?: number; timeSavingsWithCcs?: number };
  currency: Currency;
  productionType: ProductionType;
  onSuggestedPressureChange: (value?: number) => void;
  onTimeSavingsWithCcsChange: (value?: number) => void;
}

const SystemProposalStep: React.FunctionComponent<Props> = (props) => {
  const suggestedPressures = [locale[props.currency].pressure1, locale[props.currency].pressure2];

  return (
    <Form
      initialValues={props.initial}
      labelWrap
      labelCol={{ offset: 4, span: 6 }}
      wrapperCol={{ span: 8 }}
      requiredMark="optional"
      colon={false}
    >
      <Form.Item
        name="suggestedPressure"
        label={`Suggested pressure [${locale[props.currency].pressureUnit}]`}
        rules={[{ required: true, message: <></> }]}
      >
        <Select onChange={(value) => props.onSuggestedPressureChange(value)}>
          {suggestedPressures.map((pressure) => (
            <Select.Option key={pressure} value={pressure}>
              {pressure}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="timeSavingsWithCcs"
        label="Time savings with CCS"
        rules={[{ required: true, message: <></> }]}
        tooltip={
          <div>
            On the basis of the current analysis and the switch to the proposed Lagafors system, the following time
            savings are estimated
          </div>
        }
      >
        <InputNumber
          min={MIN_TIME_SAVINGS_WITH_CCS}
          addonAfter={<span>%</span>}
          onChange={(value) => props.onTimeSavingsWithCcsChange(value === null ? undefined : value)}
          decimalSeparator=","
        />
      </Form.Item>
    </Form>
  );
};

export default SystemProposalStep;
