import { Auth, API } from "aws-amplify";
import { Locale } from "../locales/types";
import { Currency } from "../pages/ProjectCreationPage/locale";

export interface ProjectResponse {
  project: Project;
  lts: Lts;
  paymentPlans: any;
}

export interface Chemical {
  consumption?: number;
  cost?: number;
}

export interface EmailContent {
  address: string;
  subject: string;
  text: string;
}

export interface Project {
  id: string;
  name: string;
  type: ProjectType;
  customer: string;
  currency: Currency;
  productionType: ProductionType;
  waterPressure: number;
  dosing: Dosing;
  numberOfChemicals: number;
  numberOfSatelliteStations: number;
  numberOfUsersOnWater: number;
  numberOfUsersOnChemicals: number;
  ccu: boolean;
  expansion: number;
  lts: boolean;
  pipes: boolean;
  pipesCostPercentage: number;
  installation: boolean;
  installationCostPercentage: number;
  serviceAgreement36Months: boolean;
  serviceCostPercentage: number;
  paymentPlan: string | null;
  status: string;
  probability: number;
  products: ProjectProduct[];
  fixedPrice: number;
  createdAt: string;
  roiData?: {
    productionAmount: number;
    productionUnit: string;
    currentWaterPressure: number;
    suggestedWaterPressure: number;
    numberOfCleaningSessions: number;
    numberOfCleaners: number;
    cleaningSessionDuration: number;
    flushingDuration: number;
    currentWaterConsumption: number;
    heatedWaterCost: number;
    wasteWaterCost: number;
    timeSavingsWithCcs: number;
    chemicals: Chemical[];
    containersPurchasedToday: boolean;
    cleanerCost: number;
  };
}

export interface Lts {
  vccIds: string[];
  multiIds: string[];
  multiXLIds: string[];
  products: ProjectProduct[];
}

export interface ProjectProduct {
  id: string;
  name: string;
  price: number;
  amount: number;
  media: string;
  family: string;
}

export interface Settings {
  InstallationCostPercentageCentral: number;
  InstallationCostPercentageDecentral: number;
  PipesCostPercentageCentral: number;
  PipesCostPercentageDecentral: number;
  ServiceCostPercentage: number;
  EURConversionRate: number;
  USDConversionRate: number;
  USDSurgeChargePercentage: number;
  USDServiceCostPercentage: number;
}

export enum ProjectType {
  Offer = "offer",
  OfferWithRoi = "offerWithRoi",
}

export enum ProductionType {
  Meat = "meat",
  Vegetarian = "vegetarian",
  Bakery = "bakery",
  Beverage = "beverage",
  PreparedFood = "preparedFood",
  Dairy = "dairy",
  Poultry = "poultry",
  Fish = "fish",
  Other = "other",
}

export enum Dosing {
  Central = "central",
  Decentral = "decentral",
}

export type CreateProjectRequestBody = Omit<Project, "id" | "status" | "products" | "createdAt">;

export type UpdateProjectRequestBody = Partial<Omit<Project, "id" | "createdAt">>;

class ProjectsService {
  /**
   * Could be moved to a base AuthenticatedService
   *
   * @returns authorisation header to include in requests
   */
  static async authorizationHeader(): Promise<{ Authorization: string }> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    return { Authorization: `Bearer ${token}` };
  }

  static async getProjects(): Promise<Project[]> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const response = await API.get("API", "/v1/projects", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.map((project: any) => project);
  }

  static async getProject(id: string): Promise<ProjectResponse> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const response = await API.get("API", `/v1/projects/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }

  static async createProject(create: CreateProjectRequestBody): Promise<string> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const response = await API.post("API", "/v1/projects", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: create,
    });

    return response.data.id;
  }

  static async updateProject(id: string, update: UpdateProjectRequestBody) {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    await API.patch("API", `/v1/projects/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: update,
    });
  }

  static async deleteProject(id: string) {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    await API.del("API", `/v1/projects/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async fetchSettings(): Promise<Settings> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const response = await API.get("API", "/v1/settings/general", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }

  static async updateSettings(settings: Settings): Promise<void> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    await API.patch("API", "/v1/settings/general", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: settings,
    });
  }

  /**
   * Request a generated PDF for the project ID provided.
   *
   * @param projectId to generate the PDF for
   * @param type of project, defaults to ProjectType.Offer
   * @returns the generated PDF
   */
  static async getPdf(project: Project, locale: Locale): Promise<Blob> {
    const { id: projectId, type } = project;
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const params = new URLSearchParams();

    params.append("locale", locale);

    let endpoint =
      type === ProjectType.Offer ? `/v1/projects/${projectId}/pdf` : `/v1/current-situation-analyzer/${projectId}/pdf`;

    endpoint += `?${params.toString()}`;

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/pdf",
      },
      responseType: "blob",
    };

    return API.get("API", endpoint, options);
  }

  static async sendEmail(project: Project, locale: Locale, values: EmailContent): Promise<void> {
    const { id: projectId, type } = project;
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const params = new URLSearchParams();

    params.append("locale", locale);

    let endpoint =
      type === ProjectType.Offer
        ? `/v1/projects/${projectId}/email`
        : `/v1/current-situation-analyzer/${projectId}/email`;

    endpoint += `?${params.toString()}`;

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: values,
    };

    return API.post("API", endpoint, options);
  }
}

export default ProjectsService;
