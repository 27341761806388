export const formatNumber = (value: number | string | undefined): string => {
  if (value === undefined) return "";

  // Ensure the value is in string form and use a dot as the decimal separator if it's a number
  let stringValue = typeof value === "number" ? value.toString() : value;

  // Replace comma with dot if input by user for internal conversion
  stringValue = stringValue.replace(/,/g, ".");

  // Split into integer and decimal parts to handle them separately
  let [integerPart, decimalPart] = stringValue.split(".");

  // Format the integer part with spaces as thousand separators
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  // Reassemble the parts, using a comma for the decimal separator if there's a decimal part
  // Ensure no space is added before the comma
  return decimalPart ? `${integerPart},${decimalPart}` : integerPart;
};

export const parseNumber = (value: string | undefined): number => {
  if (!value) return 0; // Return 0 for undefined or empty input

  // Standardize decimal separator to a period for parseFloat and remove any thousand separators (spaces)
  const standardizedValue = value.replace(/,/g, ".").replace(/\s/g, "");

  return parseFloat(standardizedValue);
};
