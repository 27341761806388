import { Auth, API } from "aws-amplify";

export interface Customer {
  id: string;
  name: string;
  currency: string;
  status: string;
}

class CustomerService {
  static async getCustomers(): Promise<Customer[]> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const response = await API.get("API", "/v1/customers", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data
  }

  static async getCustomer(id: string): Promise<Customer> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const response = await API.get("API", `/v1/customers/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data
  }
}

export default CustomerService;
