import React from "react";
import { Form, InputNumber } from "antd";

interface Props {
  initial: { numberOfStations?: number };
  onNumberOfStationsChange: (value?: number) => void;
}

const SystemStationsStep: React.FunctionComponent<Props> = (props) => {
  return (
    <Form
      initialValues={props.initial}
      labelWrap
      labelCol={{ offset: 4, span: 6 }}
      wrapperCol={{ span: 8 }}
      requiredMark="optional"
      colon={false}
    >
      <Form.Item
        name="numberOfStations"
        label="Number of satellite stations"
        rules={[{ required: true, message: <></> }]}
      >
        <InputNumber
          onChange={(value: number | null) => props.onNumberOfStationsChange(value === null ? undefined : value)}
        />
      </Form.Item>
    </Form>
  );
};

export default SystemStationsStep;
