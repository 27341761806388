import { Amplify } from "aws-amplify";

import amplifyConfiguration from "./aws-exports";

function getOauthConfig() {
  let domain = "";
  let redirectSignIn = "";
  const scope = ["email", "openid"];
  const responseType = "code";

  switch (window.location.hostname) {
    case "localhost":
      domain = "lagafors.auth.eu-north-1.amazoncognito.com";
      redirectSignIn = "http://localhost:3000/";
      break;
    case "develop.d246sq1ihr2shw.amplifyapp.com":
      domain = "lagafors.auth.eu-north-1.amazoncognito.com";
      redirectSignIn = "https://develop.d246sq1ihr2shw.amplifyapp.com/";
      break;
    case "master.d246sq1ihr2shw.amplifyapp.com":
      domain = "lagafors-sc-master.auth.eu-north-1.amazoncognito.com";
      redirectSignIn = "https://master.d246sq1ihr2shw.amplifyapp.com/";
      break;
    case "build.lagafors.se":
    case "www.build.lagafors.se":
      domain = "lagafors-sc-master.auth.eu-north-1.amazoncognito.com";
      redirectSignIn = "https://build.lagafors.se/";
      break;
    default:
      break;
  }

  return {
    domain,
    redirectSignIn,
    scope,
    responseType,
  };
}

function getApiConfig() {
  let endpoint = process.env.REACT_APP_API_URL ?? "";

  if(endpoint === "") {
    switch (window.location.hostname) {
      case "localhost":
        endpoint = "https://0qow13yzjc.execute-api.eu-north-1.amazonaws.com";
        break;
      case "develop.d246sq1ihr2shw.amplifyapp.com":
        endpoint = "https://0qow13yzjc.execute-api.eu-north-1.amazonaws.com";
        break;
      case "master.d246sq1ihr2shw.amplifyapp.com":
      case "build.lagafors.se":
      case "www.build.lagafors.se":
        endpoint = "https://ywhr7d5on1.execute-api.eu-north-1.amazonaws.com";
        break;
      default:
        break;
    }
  }

  return {
    endpoints: [
      {
        name: "API",
        endpoint: endpoint,
      },
    ],
  };
}

Amplify.configure({
  ...amplifyConfiguration,
  oauth: getOauthConfig(),
  API: getApiConfig(),
});
