import { Auth, API } from "aws-amplify";
import { Buffer } from "buffer";
import { Dosing } from "./projectService";

class PimService {
  static async getCentralWaterUnits(search: {
    waterPressure: number;
    users: number;
    expansion: number;
    pickableByConfigurator?: boolean;
  }): Promise<any> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const pickable = search.pickableByConfigurator
      ? ', "pickable_by_configurator": [{"operator": "=", "value": true}]'
      : " ";

    const params = `?limit=100&search={"family": [{"operator": "IN", "value": ["central_water_unit"]}], "pressure": [{"operator": "=", "value": ${search.waterPressure}}], "users": [{"operator": ">=", "value": ${search.users}}], "expansion": [{"operator": ">=", "value": ${search.expansion}}]${pickable}}`;

    const response = await API.get("API", "/v1/pim/api/rest/v1/products" + encodeURI(params), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response._embedded.items;
  }

  static async getCentralChemicalsUnits(search: { chemicals: number; users: number }): Promise<any> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const params = `?limit=100&search={"family": [{"operator": "IN", "value": ["central_chemicals_unit"]}], "chemicals": [{"operator": ">=", "value": ${
      search.chemicals
    }}], "users": [{"operator": ">=", "value": ${
      search.users
    }}], "pickable_by_configurator": [{"operator": "=", "value": ${true}}]}`;

    const response = await API.get("API", "/v1/pim/api/rest/v1/products" + encodeURI(params), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response._embedded.items;
  }

  static async getSatteliteStations(search: { pressure: number; chemicals: number; dosing: Dosing }): Promise<any> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const params = `?limit=100&search={"family": [{"operator": "IN", "value": ["satellite_station"]}], "pressure": [{"operator": "=", "value": ${
      search.pressure
    }}], "chemicals": [{"operator": ">=", "value": ${search.chemicals}}], "central": [{"operator": "=", "value": ${
      search.dosing === Dosing.Central ? true : false
    }}], "pickable_by_configurator": [{"operator": "=", "value": ${true}}]}`;

    const response = await API.get("API", "/v1/pim/api/rest/v1/products" + encodeURI(params), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response._embedded.items;
  }

  static async getHoseKits(search: { waterPressure: number }): Promise<any> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const params = `?limit=100&search={"family": [{"operator": "IN", "value": ["hose_kit"]}], "pressure": [{"operator": "=", "value": ${search.waterPressure}}]}`;

    const response = await API.get("API", "/v1/pim/api/rest/v1/products" + encodeURI(params), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response._embedded.items;
  }

  static async getNozzleKits(search: { waterPressure: number; dosing: Dosing }): Promise<any> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const params = `?limit=100&search={"family": [{"operator": "IN", "value": ["nozzle_kit"]}], "pressure": [{"operator": "=", "value": ${
      search.waterPressure
    }}], "central": [{"operator": "=", "value": ${
      search.dosing === Dosing.Central ? true : false
    }}], "pickable_by_configurator": [{"operator": "=", "value": ${true}}]}`;

    const response = await API.get("API", "/v1/pim/api/rest/v1/products" + encodeURI(params), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response._embedded.items;
  }

  static async getHoseReels(search: { waterPressure: number }): Promise<any> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const params = `?limit=100&search={"family": [{"operator": "IN", "value": ["hose_reel"]}], "pressure": [{"operator": "=", "value": ${
      search.waterPressure
    }}], "pickable_by_configurator": [{"operator": "=", "value": ${true}}]}`;

    const response = await API.get("API", "/v1/pim/api/rest/v1/products" + encodeURI(params), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response._embedded.items;
  }

  static async getPallets(): Promise<any> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const params = `?limit=100&search={"family": [{"operator": "IN", "value": ["pallet"]}], "pickable_by_configurator": [{"operator": "=", "value": ${true}}]}`;

    const response = await API.get("API", "/v1/pim/api/rest/v1/products" + encodeURI(params), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response._embedded.items;
  }

  static async getEmergencyShowers(): Promise<any> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const params = `?limit=100&search={"family": [{"operator": "IN", "value": ["emergency_shower"]}], "pickable_by_configurator": [{"operator": "=", "value": ${true}}]}`;

    const response = await API.get("API", "/v1/pim/api/rest/v1/products" + encodeURI(params), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response._embedded.items;
  }

  static async getOthers(): Promise<any> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const params = `?limit=100&search={"family": [{"operator": "IN", "value": ["other"]}], "pickable_by_configurator": [{"operator": "=", "value": ${true}}]}`;

    const response = await API.get("API", "/v1/pim/api/rest/v1/products" + encodeURI(params), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response._embedded.items;
  }

  static async getProductById(id: string): Promise<any> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const response = await API.get("API", `/v1/pim/api/rest/v1/products/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  }

  static async getImage(name: string): Promise<any> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const encodeAsBase64 = process.env.REACT_APP_ENCODE_PIM_IMAGES_AS_BASE64 && process.env.REACT_APP_ENCODE_PIM_IMAGES_AS_BASE64.toLowerCase() === "true";

    const response = await API.get("API", `/v1/pim/api/rest/v1/media-files/${name}/download`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: encodeAsBase64 ? "arraybuffer" : "json", // "json" is the default for Axios
    });

    // AWS API Gateway automatically encodes images as Base64, so this is needed when running the backend locally
    if(encodeAsBase64) {
      return Buffer.from(response, "binary").toString("base64");
    }

    return response;
  }
}

export default PimService;
