import React from "react";

import "./Page.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Page: React.FunctionComponent<Props> = (props) => (
  <div className={props.className ? `page ${props.className}` : "page"}>{props.children}</div>
);

export default Page;
