import React from "react";
import { Form, Select } from "antd";

import { Dosing } from "../../../services/projectService";
import { useTranslation } from "react-i18next";

interface Props {
  initial: { dosing?: Dosing };
  onDosingChange: (value: Dosing) => void;
}

const SystemDosingStep: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();

  const dosings = [
    {
      name: t("project.dosing.central"),
      value: Dosing.Central,
    },
    {
      name: t("project.dosing.decentral"),
      value: Dosing.Decentral,
    },
  ];

  return (
    <Form
      initialValues={props.initial}
      labelWrap
      labelCol={{ offset: 4, span: 6 }}
      wrapperCol={{ span: 8 }}
      requiredMark="optional"
      colon={false}
    >
      <Form.Item name="dosing" label="Dosing" rules={[{ required: true, message: <></> }]}>
        <Select onChange={(value) => props.onDosingChange(value)}>
          {dosings.map((dosing) => (
            <Select.Option key={dosing.value} value={dosing.value}>
              {dosing.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default SystemDosingStep;
