import { useState, useEffect } from "react";
import { Button, Card, Form, InputNumber, message } from "antd";
import { formatNumber, parseNumber } from "utils/percentageFormatter";
import axios from "axios";
import Page from "../../hocs/Page";
import Header from "../../components/Header";
import ProjectsService from "services/projectService";

import "./AdminPage.scss";
import { parse } from "path";

export const adminPagePath = "/admin";

interface SettingsFormValues {
  InstallationCostPercentageCentral: number;
  InstallationCostPercentageDecentral: number;
  PipesCostPercentageCentral: number;
  PipesCostPercentageDecentral: number;
  ServiceCostPercentage: number;
  EURConversionRate: number;
  USDConversionRate: number;
  USDSurgeChargePercentage: number;
  USDServiceCostPercentage: number;
}

const AdminPage = () => {
  const [form] = Form.useForm();

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const settings = await ProjectsService.fetchSettings();
        console.log("settings", settings);
        form.setFieldsValue({
          USDConversionRate: settings.USDConversionRate,
          EURConversionRate: settings.EURConversionRate,
          USDSurgeChargePercentage: settings.USDSurgeChargePercentage,
          InstallationCostPercentageCentral: settings.InstallationCostPercentageCentral,
          InstallationCostPercentageDecentral: settings.InstallationCostPercentageDecentral,
          PipesCostPercentageCentral: settings.PipesCostPercentageCentral,
          PipesCostPercentageDecentral: settings.PipesCostPercentageDecentral,
          ServiceCostPercentage: settings.ServiceCostPercentage,
          USDServiceCostPercentage: settings.USDServiceCostPercentage,
        });
      } catch (error) {
        message.error("Failed to load settings.");
      }
    };
    loadSettings();
  }, [form]);

  const handleSubmit = async (values: SettingsFormValues) => {
    try {
      await ProjectsService.updateSettings(values);
      message.success("Settings updated successfully!");
    } catch (error) {
      message.error("Failed to update settings.");
    }
  };

  return (
    <Page className="admin-page">
      <Header selectedMenuOption={null} />
      <div className="content">
        <div className="admin-container">
          <div className={"admin"}>
            <Card title="Settings Page" bordered={false}>
              <Form
                form={form}
                labelWrap
                labelCol={{ offset: 4, span: 6 }}
                wrapperCol={{ span: 8 }}
                requiredMark="optional"
                colon={false}
                onFinish={handleSubmit}
              >
                <Form.Item
                  style={{ width: "100%" }}
                  label="USD Conversion Rate"
                  name="USDConversionRate"
                  tooltip="Conversation rate from SEK to USD"
                  required
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  style={{ width: "100%" }}
                  label="EUR Conversion Rate"
                  name="EURConversionRate"
                  tooltip="Conversation rate from SEK to EUR"
                  required
                >
                  <InputNumber />
                </Form.Item>

                <Form.Item
                  style={{ width: "100%" }}
                  label="USD Surge charge"
                  name="USDSurgeChargePercentage"
                  tooltip="Additional charge for US customers to cover the cost of shipping and installation"
                  required
                >
                  <InputNumber formatter={formatNumber} parser={parseNumber} step={0.01} />
                </Form.Item>

                <Form.Item
                  style={{ width: "100%" }}
                  label="Installation cost Central"
                  name="InstallationCostPercentageCentral"
                  tooltip="Installation cost as a percentage of the total cost. This is the cost for the central systems."
                  required
                >
                  <InputNumber formatter={formatNumber} parser={parseNumber} step={0.01} />
                </Form.Item>

                <Form.Item
                  style={{ width: "100%" }}
                  label="Installation cost Decentral"
                  name="InstallationCostPercentageDecentral"
                  tooltip="Installation cost as a percentage of the total cost. This is the cost for the decentral systems."
                  required
                >
                  <InputNumber formatter={formatNumber} parser={parseNumber} step={0.01} />
                </Form.Item>

                <Form.Item
                  style={{ width: "100%" }}
                  label="Pipes cost Central"
                  name="PipesCostPercentageCentral"
                  tooltip="Material cost for pipes as a percentage of the total cost. This is the cost for the central systems."
                  required
                >
                  <InputNumber formatter={formatNumber} parser={parseNumber} step={0.01} />
                </Form.Item>

                <Form.Item
                  style={{ width: "100%" }}
                  label="Pipes cost Decentral"
                  name="PipesCostPercentageDecentral"
                  tooltip="Material cost for pipes as a percentage of the total cost. This is the cost for the decentral systems."
                  required
                >
                  <InputNumber formatter={formatNumber} parser={parseNumber} step={0.01} />
                </Form.Item>

                <Form.Item
                  style={{ width: "100%" }}
                  label="Service cost percentage"
                  name="ServiceCostPercentage"
                  tooltip="Service cost as a percentage of the total cost. This is for non US customers."
                  required
                >
                  <InputNumber formatter={formatNumber} parser={parseNumber} step={0.01} />
                </Form.Item>
                <Form.Item
                  style={{ width: "100%" }}
                  label="Service cost percentage USD"
                  name="USDServiceCostPercentage"
                  tooltip="Service cost as a percentage of the total cost. This is for US customers."
                  required
                >
                  <InputNumber formatter={formatNumber} parser={parseNumber} step={0.01} />
                </Form.Item>

                <Form.Item>
                  <div className="button-container">
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    </Page>
  );
};
export default AdminPage;
