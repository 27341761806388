import React, { useEffect, useState } from "react";
import AuthService from "../../services/authService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { FileAddOutlined, ProjectOutlined, CalculatorOutlined, SettingOutlined } from "@ant-design/icons";

import Page from "../../hocs/Page";
import Header, { MenuOption } from "../../components/Header";
import { newProjectPagePath } from "../ProjectsPage";
import { projectsPagePath } from "../ProjectsPage";

import { analyzerPagePath } from "../AnalyzerPage";

import "./HomePage.scss";

export const homePagePath = "/home";

const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const user = (await AuthService.getCurrentUser()) as any;
        console.log("user payload", user.signInUserSession.accessToken.payload["cognito:groups"]);
        if (user.signInUserSession.accessToken.payload["cognito:groups"]) {
          if (user.signInUserSession.accessToken.payload["cognito:groups"].includes("admin")) {
            setIsAdmin(true);
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    loadUser();
  }, []);

  const handleNewProjectButtonClick = () => {
    navigate(newProjectPagePath);
  };

  const handleProjectsButtonClick = () => {
    navigate(projectsPagePath);
  };

  const handleHygieneCalculatorButtonClick = () => {
    navigate(analyzerPagePath);
  };

  const handleAdminButtonClick = () => {
    navigate("/admin");
  };

  return (
    <Page className="home-page">
      <Header selectedMenuOption={MenuOption.Home} />
      <div className="actions">
        <Button className="action" onClick={handleNewProjectButtonClick}>
          <FileAddOutlined className="icon" />
          <div>{t("homePage.newProjectButton")}</div>
        </Button>
        <Button className="action" onClick={handleProjectsButtonClick}>
          <ProjectOutlined className="icon" />
          <div>{t("homePage.projectsButton")}</div>
        </Button>
        {isAdmin && (
          <>
            <Button className="action" onClick={handleHygieneCalculatorButtonClick}>
              <CalculatorOutlined className="icon" />
              <div>Current situation analyzer</div>
            </Button>
            <Button className="action" onClick={handleAdminButtonClick}>
              <SettingOutlined className="icon" />
              <div>Settings</div>
            </Button>
          </>
        )}
      </div>
    </Page>
  );
};

export default HomePage;
