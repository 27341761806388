import { Auth, API } from "aws-amplify";
import { Locale } from "../locales/types";
import { Currency } from "../pages/ProjectCreationPage/locale";
import { EmailContent } from "./projectService";


/**
 * Data about return on investment.
 */
export type RoiInformation = {
  currency: Currency,
  currentWaterPressure: number,
  suggestedWaterPressure: number,
  numberOfCleaningSessions: number,
  numberOfCleaners: number,
  cleaningSessionDuration: number,
  flushingDuration: number,
  currentWaterConsumption: number,
  heatedWaterCost: number,
  wasteWaterCost: number,
  timeSavingsWithCcs: number,
  chemicals: { consumption: number, cost: number}[],
  containersPurchasedToday: boolean,
  cleanerCost: number,
}

type PdfData = RoiInformation & {customerId?: string}

export type EmailCSAData = {
  currentSituationAnalyzerData: PdfData,
  email: EmailContent,
}

/**
 * CsaService handle data regarding the current situation analyzer.
 */
class CsaService {
  static async generatePdf(body: PdfData, locale: Locale): Promise<Blob> {
    if (!body.customerId) return Promise.reject("missing customerId");
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const params = new URLSearchParams({ locale });

    return API.post("API", `/v1/current-situation-analyzer/pdf?${params.toString()}`, {
      headers: {
        Accept: "application/pdf",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      responseType: "blob",
      body,
    });
  }

  static async sendEmail(body: EmailCSAData, locale: Locale): Promise<Blob> {
    if (!body.currentSituationAnalyzerData.customerId) return Promise.reject("missing customerId");
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const params = new URLSearchParams({ locale });

    return API.post("API", `/v1/current-situation-analyzer/email?${params.toString()}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body,
    });
  }
}

export default CsaService;
