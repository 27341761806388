import { useEffect, useState } from "react";
import { Button, Card, Spin } from "antd";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import Header from "../../components/Header";
import Page from "../../hocs/Page";
import { projectPagePath } from "../ProjectPage";
import ProjectsService, { Project } from "../../services/projectService";
import SystemServiceStep from "../ProjectCreationPage/SystemServiceStep";
import { projectCreationPagePath } from '../ProjectCreationPage';
import "./ProjectEditPage.scss";

export const projectEditPagePath = "/projects/:projectId/edit";

enum PageState {
  READY = "ready",
  LOADING = "loading",
  INVALID = "invalid",
}

const ProjectEditPage = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const [pageState, setPageState] = useState(PageState.LOADING);
  const [submitting, setSubmitting] = useState(false);

  const [project, setProject] = useState<Project>();
  const [lts, setLts] = useState(false);
  const [pipes, setPipes] = useState(false);
  const [installation, setInstallation] = useState(false);
  const [serviceAgreement36Months, setServiceAgreement36Months] = useState(false);
  const [plans, setPlans] = useState("");

  useEffect(() => {
    if (!projectId) return;
    const getProjectData = async () => {
      return ProjectsService.getProject(projectId);
    };

    setPageState(PageState.LOADING);
    getProjectData()
      .then(({ project }) => {
        setProject(project);
        setLts(project.lts);
        setPipes(project.pipes);
        setInstallation(project.installation);
        setServiceAgreement36Months(project.serviceAgreement36Months);
        setPageState(PageState.READY);
      })
      .catch(() => setPageState(PageState.INVALID));
  }, [projectId]);

  const updateProject = async () => {
    if (!projectId) {
      console.warn("cannot update project without projectId");
      return;
    }
    try {
      setSubmitting(true);
      const newProject = { ...project, lts, pipes, installation, serviceAgreement36Months, plans };
      await ProjectsService.updateProject(projectId, newProject);
      navigate(generatePath(projectPagePath, { projectId }));
    } catch (error) {
      console.warn("could not update project:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Page className="project-edit-page">
      <Header selectedMenuOption={null} />
      <div className="content">
        {pageState !== PageState.READY ? (
          <Spin size="large" />
        ) : (
          <Card
            className="step"
            title="Lagafors system recommendations"
            extra={
              <div className="step-extra">
                <Button
                  type="default"
                  loading={submitting ? { delay: 500 } : false}
                  onClick={() => navigate(generatePath(projectCreationPagePath, { projectId: projectId ?? "" }))}
                >
                  Edit project data
                </Button>
                <Button type="primary" loading={submitting ? { delay: 500 } : false} onClick={updateProject}>
                  Update
                </Button>
              </div>
            }
          >
            <SystemServiceStep
              lts={lts}
              pipes={pipes}
              installation={installation}
              serviceAgreement36Months={serviceAgreement36Months}
              plans={plans}
              onLtsChange={setLts}
              onPipesChange={setPipes}
              onInstallationChange={setInstallation}
              onServiceAgreement36MonthsChange={setServiceAgreement36Months}
              onPlansChange={setPlans}
            />
          </Card>
        )}
      </div>
    </Page>
  );
};

export default ProjectEditPage;
