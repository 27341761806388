import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Radio, Modal, message } from "antd";

import ProjectsService, { Lts, Project } from "../../services/projectService";
import CustomerService, { Customer } from "../../services/customerService";
import { projectsPagePath } from "../ProjectsPage";
import Page from "../../hocs/Page";
import Header from "../../components/Header";
import Details from "./Details";
import Products from "./Products";

import "./ProjectPage.scss";
import PimService from "../../services/pimService";
import Summary from "./Summary";
import { projectEditPagePath } from "../ProjectEditPage";

export const projectPagePath = "/projects/:projectId";

const ProjectPage = () => {
  const navigate = useNavigate();

  const { projectId } = useParams();

  const [tab, setTab] = useState<"details" | "products" | "summary">("products");

  const [project, setProject] = useState<Project>();
  const [lts, setLts] = useState<Lts>();
  const [paymentPlans, setPaymentPlans] = useState<any>();
  const [customer, setCustomer] = useState<Customer>();
  const [status, setStatus] = useState<string>("notOrdered");
  const [probability, setProbability] = useState<number>(0);
  const [images, setImages] = useState<{ [key: string]: string }>({});
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (projectId === undefined) {
      return;
    }

    ProjectsService.getProject(projectId)
      .then((project) => {
        setProject(project.project);
        setLts(project.lts);
        setPaymentPlans(project.paymentPlans);
        setStatus(project.project.status);
        setProbability(project.project.probability);

        CustomerService.getCustomer(project.project.customer)
          .then((customer) => {
            setCustomer(customer);
          })
          .catch(() => {
            setCustomer(undefined);
          });

        for (const product of project.project.products) {
          PimService.getImage(product.media).then((image) => {
            setImages((current) => {
              return {
                ...current,
                ...{ [product.id]: image },
              };
            });
          });
        }
      })
      .catch(() => {
        setProject(undefined);
        setLts(undefined);
      });
  }, [projectId]);

  const navigateToEditPage = () => {
    if (projectId) {
      navigate(generatePath(projectEditPagePath, { projectId }));
    }
  };

  const showEditConfirmationModal = () => {
    Modal.confirm({
      title: "Are you sure you want to edit this project?",
      content:
        "This will recalculate equipment as well as prices and when you save it, all manual changes you have made will be lost.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        navigateToEditPage();
      },
    });
  };

  const deleteProject = async () => {
    Modal.confirm({
      title: "Are you sure you want to delete this project?",
      content: "This action cannot be undone.",
      onOk: async () => {
        if (projectId === undefined) {
          return;
        }
        try {
          setDeleting(true);
          await ProjectsService.deleteProject(projectId);
          navigate(projectsPagePath);
        } catch (error) {
          // TODO: handle the error
        } finally {
          setDeleting(false);
        }
      },
    });
  };

  const saveProject = async () => {
    if (projectId === undefined) {
      return;
    }

    try {
      setSaving(true);
      await ProjectsService.updateProject(projectId, project!);
      setTab("products");
      message.success("Project successfully saved");
    } catch (error) {
      console.error("Failed to save project:", error);
      message.error("Failed to save project");
    } finally {
      setSaving(false);
    }
  };

  const updateProduct = (productId: string, amount: number) => {
    let isCombiProduct = false;

    const updatedProducts =
      project?.products?.map((product) => {
        if (product.id === productId) {
          console.log("Family:", product.family, "Name:", product.name);
          if (product.family === "satellite_station" && product.name.toLowerCase().includes("combi")) {
            isCombiProduct = true;
          }
          return { ...product, amount };
        }
        return product;
      }) || []; // Fallback to an empty array if project or products is undefined

    if (isCombiProduct && updatedProducts.length > 0) {
      const combiProductCount = updatedProducts.reduce((count, product) => {
        if (product.family === "satellite_station" && product.name.toLowerCase().includes("combi")) {
          return count + product.amount;
        }
        return count;
      }, 0);

      const product40751Index = updatedProducts.findIndex((product) => product.id === "40751");
      if (product40751Index !== -1) {
        updatedProducts[product40751Index] = { ...updatedProducts[product40751Index], amount: combiProductCount };
      }
    }

    setProject((oldValue) => {
      if (!oldValue) {
        return oldValue;
      }
      return { ...oldValue, products: updatedProducts, fixedPrice: -1 };
    });
  };

  const updateProbability = (newProbability: number) => {
    setProbability(newProbability);

    setProject((prevProject) => {
      if (!prevProject) {
        return prevProject;
      }

      return {
        ...prevProject,
        probability: newProbability,
      };
    });
  };

  const updateStatus = (newStatus: string) => {
    setStatus(newStatus);

    setProject((prevProject) => {
      if (!prevProject) {
        return prevProject;
      }

      return {
        ...prevProject,
        status: newStatus,
      };
    });
  };

  return (
    <Page className="project-page">
      <Header selectedMenuOption={null} />
      <div className="project-content">
        {project && customer && (
          <Card
            className="project-card"
            title={project.name}
            extra={
              <div className="card-extra">
                <Radio.Group value={tab} onChange={(e) => setTab(e.target.value)}>
                  <Radio.Button value="details">Details</Radio.Button>
                  <Radio.Button value="products">Products</Radio.Button>
                  <Radio.Button value="summary">Summary</Radio.Button>
                </Radio.Group>
                <div className="management-actions">
                  <Button.Group>
                    <Button
                      className="delete-button"
                      danger
                      onClick={deleteProject}
                      loading={deleting ? { delay: 500 } : false}
                    >
                      Delete
                    </Button>
                    {projectId && (
                      <Button className="edit-button" onClick={showEditConfirmationModal}>
                        Edit
                      </Button>
                    )}
                    <Button className="save-button" onClick={saveProject} loading={saving ? { delay: 500 } : false}>
                      Save
                    </Button>
                  </Button.Group>
                </div>
              </div>
            }
          >
            {tab === "details" && (
              <Details
                project={project}
                customer={customer.name}
                status={status}
                probability={probability}
                onStatusChange={updateStatus}
                onProbabilityChange={updateProbability}
              />
            )}
            {tab === "products" && <Products project={project} images={images} updateProduct={updateProduct} />}
            {tab === "summary" && <Summary project={project} lts={lts!} paymentPlans={paymentPlans} />}
          </Card>
        )}
      </div>
    </Page>
  );
};

export default ProjectPage;
