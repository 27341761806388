import React, { useEffect, useState } from "react";
import AuthService from "../../services/authService";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PageHeader, Image, Radio, Modal } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

import lagaforsLogo from "../../images/lagafors-logo.png";
import productConfiguratorLogo from "images/product-configurator-logo.webp";
import { homePagePath } from "../../pages/HomePage";
import { projectsPagePath } from "../../pages/ProjectsPage";
import { analyzerPagePath } from "../../pages/AnalyzerPage";

import "./Header.scss";

export enum MenuOption {
  Home = "home",
  Customers = "customers",
  Projects = "projects",
  Analyzer = "analyzer",
}

interface Props {
  selectedMenuOption: MenuOption | null;
  hideMenu?: boolean;
}

const Header: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");

  useEffect(() => {
    const loadUser = async () => {
      try {
        const user = (await AuthService.getCurrentUser()) as any;
        if (user && user.storage && user.storage.user) {
          const userStorageData = JSON.parse(user.storage.user);
          if (userStorageData && userStorageData.name) {
            setUserName(userStorageData.name);
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    loadUser();
  }, []);

  const confirmSignOut = () => {
    Modal.confirm({
      title: "Confirm Sign Out",
      content: "Are you sure you want to sign out?",
      onOk() {
        logout();
      },
    });
  };

  const logout = () => {
    AuthService.signOut();
    navigate(homePagePath);
  };

  return (
    <PageHeader
      title={
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "60px" }}>
          <img
            alt="Product Configurator Logo"
            src={productConfiguratorLogo}
            style={{ height: "60px", objectFit: "contain", maxWidth: "100%" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "60px",
              marginLeft: "10px",
              alignItems: "start",
            }}
          >
            <img
              alt="Lagafors Logo"
              src={lagaforsLogo}
              style={{ height: "35px", objectFit: "contain", maxWidth: "100%" }}
            />
            <p className="header-subtitle" style={{ fontWeight: "bolder" }}>
              PRODUCT CONFIGURATOR
            </p>
          </div>
        </div>
      }
      // title={
      //   <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
      //     <Image preview={false} src={productConfiguratorLogo} height={60} />
      //     <div style={{ display: "flexf", flexDirection: "column", height: "60px", marginLeft: "10px" }}>
      //       <p className="header-title" style={{ fontWeight: "bolder" }}>
      //         PRODUCT CONFIGURATOR
      //       </p>
      //       <div style={{ display: "flex", flexDirection: "row" }}>
      //         <p className="header-subtitle">by</p>
      //         <Image preview={false} src={lagaforsLogo} height={14} style={{ marginLeft: "8px" }} />
      //       </div>
      //     </div>
      //   </div>
      // }
      // title={
      //   <div style={{ display: "flex flex-row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
      //     <Image preview={false} src={lagaforsLogo} width={250} />
      //   </div>
      // }
      extra={
        props.hideMenu ? null : (
          <>
            <Radio.Group
              value={props.selectedMenuOption}
              onChange={(e) => {
                switch (e.target.value) {
                  case MenuOption.Home:
                    navigate(homePagePath);
                    break;
                  case MenuOption.Projects:
                    navigate(projectsPagePath);
                    break;
                  case MenuOption.Analyzer:
                    navigate(analyzerPagePath);
                    break;
                }
              }}
            >
              <Radio.Button value={MenuOption.Home}>{t("header.homeMenuOption")}</Radio.Button>
              {/* <Radio.Button value={MenuOption.Customers}>{t("header.customersMenuOption")}</Radio.Button> */}
              {/* <Radio.Button value={MenuOption.Projects}>{t("header.projectsMenuOption")}</Radio.Button>
              <Radio.Button value={MenuOption.Analyzer}>Current situation analyzer</Radio.Button> */}
            </Radio.Group>
            <Radio.Group>
              <Radio.Button value={null} onClick={confirmSignOut}>
                Sign out <LogoutOutlined />
              </Radio.Button>
            </Radio.Group>
          </>
        )
      }
    />
  );
};

export default Header;
