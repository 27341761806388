import React from "react";
import { Form, InputNumber } from "antd";

const MIN_TIME_SAVINGS_WITH_CCS = 0;

interface Props {
  initial: { timeSavingsWithCcs?: number };
  onTimeSavingsWithCcsChange: (value?: number) => void;
}

const SystemSavingsStep: React.FunctionComponent<Props> = (props) => {
  return (
    <Form
      initialValues={props.initial}
      labelWrap
      labelCol={{ offset: 4, span: 6 }}
      wrapperCol={{ span: 8 }}
      requiredMark="optional"
      colon={false}
    >
      <Form.Item
        name="timeSavingsWithCcs"
        label="Time savings with CCS"
        rules={[{ required: true, message: <></> }]}
        tooltip={
          <div>
            On the basis of the current analysis and the switch to the proposed Lagafors system, the following time
            savings are estimated
          </div>
        }
      >
        <InputNumber
          min={MIN_TIME_SAVINGS_WITH_CCS}
          addonAfter={<span>%</span>}
          onChange={(value) => props.onTimeSavingsWithCcsChange(value === null ? undefined : value)}
        />
      </Form.Item>
    </Form>
  );
};

export default SystemSavingsStep;
