import React, { useState } from "react";
import { Checkbox, Collapse, Form, InputNumber } from "antd";

import "./CurrentChemicalsStep.scss";
import { locale, Currency } from "../../../ProjectCreationPage/locale";

interface Props {
  initial: any;
  currency: Currency;
  onNumberOfChemicalsChange: (value?: number) => void;
  onContainersPurchasedTodayChange: (value: boolean) => void;
  onChemicalConsumptionChange: (id: number, value?: number) => void;
  onChemicalCostChange: (id: number, value?: number) => void;
  onDecentralChange: (value: boolean) => void;
}

const MIN_NUMBER_OF_CHEMICALS = 1;
const MAX_NUMBER_OF_CHEMICALS = 5;
const MIN_CONSUMPTION = 0;
const MIN_COST = 0;
const BOX_WIDTH = "45px";

const CurrentChemicalsStep: React.FunctionComponent<Props> = (props) => {
  const [form] = Form.useForm();

  const [numberOfChemicals, setNumberOfChemicals] = useState<number | undefined>(props.initial.numberOfChemicals);
  const [decentralDosing, setDecentralDosing] = useState<boolean>(props.initial.decentralDosing);

  const handleNumberOfChemicalsChange = (value: number | null) => {
    setNumberOfChemicals(value === null ? undefined : value);
    props.onNumberOfChemicalsChange(value === null ? undefined : value);
  };

  return (
    <Form
      initialValues={props.initial}
      className="current-chemicals-step"
      form={form}
      labelWrap
      labelCol={{ offset: 4, span: 6 }}
      wrapperCol={{ span: 8 }}
      requiredMark="optional"
      colon={false}
    >
      <Form.Item name="decentralDosing" label="Decentral dosing" rules={[{ required: true, message: <></> }]}>
        <Checkbox
          checked={decentralDosing}
          onChange={(e) => {
            setDecentralDosing(e.target.checked);
            props.onDecentralChange(e.target.checked);
          }}
        />
      </Form.Item>
      {!decentralDosing && (
        <>
          <Form.Item name="numberOfChemicals" label="Number of chemicals" rules={[{ required: true, message: <></> }]}>
            <InputNumber
              min={MIN_NUMBER_OF_CHEMICALS}
              max={MAX_NUMBER_OF_CHEMICALS}
              value={numberOfChemicals}
              onChange={handleNumberOfChemicalsChange}
            />
          </Form.Item>
          <Form.Item
            name="containersPurchasedToday"
            label="Containers purchased today"
            rules={[{ required: true, message: <></> }]}
          >
            <Checkbox
              checked={props.initial.containersPurchasedToday}
              onChange={(e) => props.onContainersPurchasedTodayChange(e.target.checked)}
            />
          </Form.Item>

          <div className="chemicals">
            {numberOfChemicals &&
              [...Array(numberOfChemicals).keys()].map((i) => (
                <Collapse className="chemical" key={i} defaultActiveKey={[i]}>
                  <Collapse.Panel key={i} header={`Chemical ${i + 1}`}>
                    <Form.Item
                      name={`consumption${i}`}
                      label="Consumption"
                      rules={[{ required: true, message: <></> }]}
                      labelCol={{ offset: 2, span: 8 }}
                      wrapperCol={{ span: 10 }}
                    >
                      <InputNumber
                        min={MIN_CONSUMPTION}
                        addonAfter={
                          <span style={{ width: BOX_WIDTH, display: "inline-block" }}>
                            {locale[props.currency].chemicalsConsumption}
                          </span>
                        }
                        onChange={(value) => props.onChemicalConsumptionChange(i, value === null ? undefined : value)}
                      />
                    </Form.Item>
                    <Form.Item
                      name={`cost${i}`}
                      label="Cost"
                      rules={[{ required: true, message: <></> }]}
                      labelCol={{ offset: 2, span: 8 }}
                      wrapperCol={{ span: 10 }}
                    >
                      <InputNumber
                        min={MIN_COST}
                        addonAfter={
                          <span style={{ width: BOX_WIDTH, display: "inline-block" }}>
                            {locale[props.currency].chemicalsCost}
                          </span>
                        }
                        onChange={(value) => props.onChemicalCostChange(i, value === null ? undefined : value)}
                        decimalSeparator=","
                      />
                    </Form.Item>
                  </Collapse.Panel>
                </Collapse>
              ))}
          </div>
        </>
      )}
    </Form>
  );
};

export default CurrentChemicalsStep;
